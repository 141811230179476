@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap");
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@300;400;600;700;800&display=swap");
* {
  font-family: "Commissioner", sans-serif !important;
}
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-c2k196[aria-selected="true"],
.css-c2k196[data-selected] {
  color: #ff1a57 !important;
  border-color: #ff1a57 !important;
}

.css-1xhq01z {
  border: none !important;
}

.css-1yxgpcs {
  z-index: 999;
}

.pagination {
  background-color: #fff !important;
  border-radius: 5px;
  margin: 10px 0px;
}

.pagination li.page-item a,
.pagination li.page-item.active a,
.pagination li.page-item.active a {
  background: transparent;
  color: #6b6162;
  border: none;
  padding: 9px 18px;
  font-weight: 600;
  font-size: 14px;
}

.pagination li.page-item.active a {
  background-color: #ff1a57;
  color: #fff;
}

.pagination li.page-item.active a:focus {
  box-shadow: none;
}

.css-ctbbt1 {
  white-space: normal !important;
}


/* .pagination li.page-item:first-child {margin-left:auto;} */

 /* .pagination li.page-item:last-child {margin-left:auto;} */
/* 
 .disabled-class {
  position: absolute !important;
  right: 50px !important;
 }

 .page-item:first-child .page-link {
  position: absolute;
  right: 55px !important;
 } */
 @media  screen and (min-device-width: 768px) and (max-device-width: 1024px) 
 
 {
/* .css-1lioj11 {width:13% !important};
.css-kcsi8b {width: 88% !important;}
 */

 }/* applied to lg also */

 @media  (min-device-width : 1280px) and (max-device-width : 1366px)  {

.css-kcsi8b {width: 84% !important;}

}


@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap);

body {
    font-family: "Roboto", sans-serif;
    background: #EFF1F3;
    min-height: 100vh;
    position: relative;
}

.section-50 {
    padding: 50px 0;
}

.m-b-50 {
    margin-bottom: 50px;
}

.dark-link {
    color: #333;
}

.heading-line {
    position: relative;
    padding-bottom: 5px;
}

.heading-line:after {
    content: "";
    height: 4px;
    width: 75px;
    background-color: #FF1A57;
    position: absolute;
    bottom: 0;
    left: 0;
}

.notification-ui_dd-content {
    margin-bottom: 30px;
}

.notification-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 7px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
    border-radius: 10px;
    overflow: hidden;
    border: none;
}

.notification-list--unread {
    border-left: 2px solid #FF1A57;
}

.notification-list .notification-list_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.notification-list .notification-list_content .notification-list_img img {
    height: 48px;
    width: 48px;
    border-radius: 50px;
    margin-right: 20px;
}

.notification-list .notification-list_content .notification-list_detail p {
    margin-bottom: 5px;
    line-height: 1.2;
}

.notification-list .notification-list_feature-img img {
    height: 48px;
    width: 48px;
    border-radius: 5px;
    margin-left: 20px;
}